import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Header from '@/common/header';
import Sponsors from '@/components/sponsors';
import CreateAccount from '@/components/trial/create-account';
import TrialHero from '@/components/trial/hero';
import TrialCreditCard from '@/components/trial/one-week/trial-credit-card';
import Perks from '@/components/trial/perks';
import PromoSection from '@/components/trial/promo-section';
import { useAuth } from '@/hooks/use-auth';
import type { CustomTrialPlans } from '@/interface/trial-plans';
import NextSeoComponent from '@/layouts/next-seo-component';
import type { AppDispatch } from '@/store';
import { getPlansTrial } from '@/store/plan';
import { Main } from '@/templates/main';

export default function Trial() {
  const auth = useAuth();
  const { user } = auth;
  const [plans, setPlans] = useState<any[] | CustomTrialPlans>([]);
  const planId = !Array.isArray(plans) ? plans.id : null;
  const daysTrial = !Array.isArray(plans) ? plans.trial_days : plans[0]?.trial_period;
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    try {
      dispatch(getPlansTrial()).then((res: any) => {
        setPlans(res.payload?.data);
      });
    } catch (e) {
      /* empty */
    }
  }, [dispatch]);

  return (
    <>
      <Header variant='primary' />
      <Main meta={<NextSeoComponent title='NoPing - Trial' description='NoPing - Trial' />} className='mb-28'>
        <div className='flex flex-col gap-20'>
          <TrialHero daysTrial={daysTrial} />
          <Sponsors />
          <Perks />
          <PromoSection />
          {!user ? <CreateAccount custom={planId} daysTrial={daysTrial} /> : <TrialCreditCard plans={plans} daysTrial={daysTrial} />}
        </div>
      </Main>
    </>
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'components/country-name', 'components/checkout', 'components/trial/hero', 'components/trial/perks', 'components/trial/promo-section', 'components/trial/create-account', 'pages/trial/index', 'pages/pricing', 'components/terms-use', 'components/plans'])),
    },
  };
}
