import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import GameIcon from '@/assets/svg/game-icon';

export default function PromoSection() {
  const { t } = useTranslation('components/trial/promo-section');

  return (
    <div className='flex items-center justify-center'>
      <div className='flex w-full max-w-[1400px] flex-col items-center gap-10 px-5 xl:flex-row'>
        <div className='w-full overflow-hidden rounded-xl ipad:max-w-[60%]'>
          <Image className='size-full object-cover object-center' alt='Screen from app' src={t('trial.url_img')} width={659} height={386} />
        </div>
        <div className='flex max-w-lg flex-col gap-y-9'>
          <div className='flex flex-col gap-y-6 text-left'>
            <div className='flex flex-col gap-y-6 text-xl font-[700] leading-none text-zinc-300'>
              <GameIcon />
              <div className='text-xl uppercase text-[#565B63]'> {t('trial.unlimited')} </div>
            </div>
            <div className='flex flex-col gap-y-5'>
              <h2 className='text-5xl font-[700] leading-none text-white'>{t('trial.no_more_games')}</h2>
              <p className='text-base font-[500] leading-snug text-[#53535F]'>{t('trial.unlock')}</p>
            </div>
          </div>

          <Link href='#form' className='w-full rounded-xl bg-green-300 py-4 text-center text-black transition-opacity hover:opacity-80 mobile:w-fit mobile:px-10'>
            <span className='font-bold uppercase'> {t('trial.button_start_free_trial')}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
