import { useTranslation } from 'next-i18next';

import BoltIcon from '@/assets/svg/bolt-icon';

export default function Perks() {
  const { t } = useTranslation('components/trial/perks');

  return (
    <div className='flex items-center justify-center'>
      <div className='grid w-full max-w-[1400px] gap-5 px-5 tablet:grid-cols-2'>
        <div className="h-[347px] w-full rounded-xl bg-[#9428FF] bg-no-repeat min-[1220px]:bg-[url('/assets/images/trial/1.webp')]">
          <div className='flex h-full items-center gap-10'>
            <div className='flex w-full flex-col items-start px-4 min-[1220px]:ml-auto min-[1220px]:max-w-[325px]'>
              <span className='text-green-300'>
                <BoltIcon />
              </span>
              <div className='mt-3 flex flex-col items-start gap-3'>
                <div className='text-2xl font-bold uppercase leading-[140%] text-white'>{t('trial.end_the_lag')}</div>
                <div className='text-sm font-semibold leading-[140%] text-white'>{t('trial.end_the_lag_description')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[347px] w-full rounded-xl bg-[#E47435] bg-no-repeat min-[1220px]:bg-[url('/assets/images/trial/bloodseeker.webp')]">
          <div className='flex h-full items-center gap-6'>
            <div className='flex flex-col items-start justify-end px-4 min-[1220px]:ml-auto min-[1220px]:max-w-[325px]'>
              <span className='text-green-300'>
                <BoltIcon />
              </span>
              <div className='mt-3 flex flex-col items-start gap-3'>
                <div className='text-2xl font-bold uppercase leading-[140%] text-white'>{t('trial.otimization_fps')}</div>
                <div className='text-sm font-semibold leading-[140%] text-white'>{t('trial.otimization_fps_description')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[347px] w-full rounded-xl bg-green-300 bg-contain bg-no-repeat min-[1220px]:bg-[url('/assets/images/trial/juggernaut.webp')]">
          <div className='flex h-full items-center gap-5'>
            <div className='flex w-full flex-col items-start px-3 min-[1220px]:ml-auto min-[1220px]:max-w-[325px]'>
              <span className='text-black'>
                <BoltIcon />
              </span>
              <div className='mt-3 flex w-full flex-col items-start gap-3'>
                <div className='text-2xl font-bold uppercase leading-[140%] text-black'>{t('trial.ip_block')}</div>
                <div className='text-sm font-semibold leading-[140%] text-black'>{t('trial.ip_block_description')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[347px] w-full rounded-xl bg-[#2ECDFF] bg-no-repeat min-[1220px]:bg-[url('/assets/images/trial/3.webp')]">
          <div className='flex h-full items-center gap-10'>
            <div className='flex w-full flex-col items-start px-4 min-[1220px]:ml-auto min-[1220px]:max-w-[325px]'>
              <span className='text-black'>
                <BoltIcon />
              </span>
              <div className='mt-3 flex flex-col items-start gap-3'>
                <div className='text-2xl font-bold uppercase leading-[140%] text-black'>{t('trial.multi_path')}</div>
                <div className='text-sm font-semibold leading-[140%] text-black'>{t('trial.multi_path_description')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
