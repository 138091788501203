import Image from 'next/image';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';

interface TrialHeroProps {
  daysTrial?: number;
}

export default function TrialHero({ daysTrial = 7 }: TrialHeroProps) {
  const { t } = useTranslation('components/trial/hero');

  return (
    <div className='flex items-center justify-center'>
      <div className='flex w-full max-w-[1400px] flex-col items-center p-5 xl:flex-row xl:py-20'>
        <div className='mb-10'>
          <h1 className='max-w-[652px] text-6xl font-bold leading-[1.1] text-white'>{t('trial.title')}</h1>
          <p className='max-w-[559px] pt-6 text-base font-[500] leading-[1.4] text-[#616C7B]'>{t('trial.description')}</p>
          <Link href='#form' className='lex mt-5 flex w-full items-center justify-center rounded-xl bg-green-300 py-4 text-center text-black transition-opacity hover:opacity-80 mobile:w-fit mobile:px-10'>
            <span className='font-bold uppercase'>
              <Trans values={{ days: daysTrial }}>{t('trial.button_days_free')}</Trans>
            </span>
          </Link>
        </div>

        <div className='mx-auto mt-10 w-full max-w-[650px] ipad:mx-0 laptop:mt-4'>
          <div className='flex flex-col gap-2'>
            <div className='flex items-end gap-4'>
              <div className='flex flex-col gap-2'>
                <p className='font-bold text-[#FF002A]'>{t('trial.without_noping')}</p>
                <video autoPlay muted loop className='w-full max-w-[450px] rounded-xl border border-[#FF002A]'>
                  <source src='/assets/video/trial/video-lagado.mp4' type='video/mp4' />
                </video>
              </div>
              <div className='ml-auto flex items-end gap-4'>
                <Image src='/assets/images/novo-logo-verde-branco.png' alt='Logo NoPing' width={120} height={32} className='flex' />
                <span className='relative -left-2 hidden font-bold text-[#AEF55C] xl:flex'>ON</span>
              </div>
            </div>
            <div className='flex w-full justify-end'>
              <video autoPlay muted loop className='w-full max-w-[450px] rounded-xl border border-green-300'>
                <source src='/assets/video/trial/video-deslagado.mp4' type='video/mp4' />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
